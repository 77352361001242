import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import jwt_decode from 'jwt-decode'

interface LoginResponse {
  id: number | null
  username: string | null
  email: string | null
  accessToken: string | null
  tokenType: string | null
  avatarKey?: string
  userType: string | null
  admin_panel?: boolean
  hr_panel?: boolean
}

interface UserState extends LoginResponse {
  loading: boolean
  error: string | null
}

export interface CustomError {
  name: 'AxiosError'
  data?: {
    message: string
    requestGUID: string
    type: string
    code: number
  }
  message: string
}

// initialize userToken from local storage
const userDetails = localStorage.getItem('userDetails')
const emptyUserState = {
  id: null,
  username: null,
  email: null,
  accessToken: null,
  tokenType: null,
  loading: false,
  error: null,
  userDetails: null,
  userType: null,
  admin_panel: false,
  hr_panel: false,
}

const initialState: UserState = !isTokenExpired()
  ? JSON.parse(userDetails as string)
  : { ...emptyUserState }

function isTokenExpired() {
  const userDetailsString = localStorage.getItem('userDetails')
  if (userDetailsString) {
    const { accessToken } = JSON.parse(userDetailsString)
    const decoded: any = jwt_decode(accessToken)
    if (decoded.exp * 1000 < Date.now()) {
      return true
    } else return false
  }

  return true
}
export const userLogin = createAsyncThunk<
  LoginResponse,
  { username: string; password: string }
>('auth/login', async ({ username, password }, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<LoginResponse>(
      `https://rme.z2atravel.com/auth/signin?username=${username}&password=${password}`,
    )
    // store user's token in local storage
    localStorage.setItem('userToken', data.accessToken!)
    return data
  } catch (error: any) {
    console.log(error)
    if ('response' in error) {
      return rejectWithValue({
        name: error.name,
        message: error.message,
        data: error.response.data,
      })
    } else {
      return rejectWithValue({
        name: error.name,
        message: error.message,
      })
    }
  }
})

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('userDetails')
      state = { ...emptyUserState }
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(userLogin.fulfilled, (state, { payload }) => {
      state = { ...payload, loading: false, error: null }
      localStorage.setItem(
        'userDetails',
        JSON.stringify({ ...payload, loading: false, error: null }),
      )
      return state
    })
    builder.addCase(userLogin.rejected, (state, { payload }: any) => {
      state.error = payload
      state.loading = false
    })
  },
})

export const { logout } = authSlice.actions

export default authSlice.reducer
