import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface LayoutControlsState {
  sidebarShow: boolean
  sidebarUnfoldable: boolean
  asideShow: boolean
  theme: string
}

const initialState: LayoutControlsState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
  asideShow: false,
  theme: 'default',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type args = { [key: string]: any }

export const layoutControlsSlice = createSlice({
  name: 'layoutControls',
  initialState,
  reducers: {
    setLayoutControls: (state, action: PayloadAction<args>) => {
      return { ...state, ...action.payload }
    },
  },
})

export const { setLayoutControls } = layoutControlsSlice.actions

export default layoutControlsSlice.reducer
