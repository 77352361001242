import { configureStore } from '@reduxjs/toolkit'

import layoutControlsReducer from './slices/layoutControls'
import authReducer from './slices/authSlice'
import { baseApi } from './baseApi'

import { rtkQueryErrorLogger } from './errorLoggerMiddleware'

export const store = configureStore({
  reducer: {
    layoutControls: layoutControlsReducer,
    authReducer: authReducer,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware, rtkQueryErrorLogger),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
