import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import { ToastContainer } from 'react-toastify'

import Login from 'views/login/Login'
import 'react-toastify/dist/ReactToastify.css'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes>
            <Route path="*" element={<DefaultLayout />} />
            {/* <Route path="*" element={<DefaultLayout />} /> */}
            <Route path="/login" element={<Login />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        pauseOnHover={true}
        pauseOnFocusLoss={false}
        closeOnClick={false}
      />
    </>
  )
}

export default App
