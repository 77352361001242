import {
  SerializedError,
  isRejectedWithValue,
  isFulfilled,
} from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { toast } from 'react-toastify'
import { CustomError } from './slices/authSlice'
import { logout } from './slices/authSlice'

const SuccessEndpoints = {
  addBusinessTool: 'Business Tool Added',
  updateBusinessTool: 'Business Tool Updated',
  deleteBusinessTool: 'Business Tool Deleted',
  addExternalAgent: 'External Agent Added',
  updateExternalAgent: 'External Agent Updated',
  deleteExternalAgent: 'External Agent Deleted',
  addHotel: 'Hotel Added',
  updateHotel: 'Hotel Updated',
  deleteHotel: 'Hotel Deleted',
  addSupplier: 'Supplier Added',
  updateSupplier: 'Supplier Updated',
  deleteSupplier: 'Supplier Deleted',
  updateAgentCommission: 'Agent Commission Updated',
  updateAgent: 'Agent Updated',
  addAgent: 'Agent Added',
  deleteAgent: 'Agent Deleted',
  addClient: 'Client Added',
  updateClient: 'Client Updated',
  deleteClient: 'Client Deleted',
  updateClientMembership: 'Client Membership Updated',
  addClientMembership: 'Client Membership Added',
  deleteClientMembership: 'Client Membership Deleted',
  updateClientPassport: 'Client Passport Updated',
  addClientPassport: 'Client Passport Added',
  deleteClientPassport: 'Client Passport Deleted',
  updateUserAvatar: 'User Avatar Updated',
  addClientTravelCredit: 'Client Travel Credit Added',
  updateUserStatus: 'User Status Updated',
  registerUser: 'User Registered',
  unlockUser: 'User Unlocked',
  updateApplicationDefaults: 'Application Defaults Updated',
}

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // console.log('action', action)
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      if (action.payload?.status === 401) {
        api.dispatch(logout())
        toast.error('Unauthorized')
        return
      }
      handleError(action.payload)
      // toast.error('We got a rejected action!')
    }

    if (isFulfilled(action)) {
      handleSuccess(action)
    }

    return next(action)
  }

function handleError(
  error: CustomError | FetchBaseQueryError | SerializedError,
) {
  // error is an AxiosError
  if ('name' in error && error.name === 'AxiosError') {
    if ('data' in error) {
      const { message, requestGUID, type } = error.data as any
      toast.error(message)
      return
    } else {
      toast.error(error.message)
      return
    }
  }

  // error is a FetchBaseQueryError
  if ('status' in error && 'data' in error) {
    const { message, requestGUID, type } = error.data as any
    toast.error(message)
    return
  } else if ('status' in error) {
    toast.error(error.error)
    return
  }

  // error is a SerializedError
  if ('error' in error) {
    toast.error(error.message)
    return
  }
}

function handleSuccess(action: any) {
  const type = action?.meta?.arg?.type

  if (type === 'mutation') {
    const endpointName: string | undefined = action?.meta?.arg?.endpointName
    // console.log('endpointName', endpointName)

    if (endpointName && endpointName in SuccessEndpoints) {
      // console.log('enpointIndex', endpointIndex, 'endpointName', endpointName)
      toast.success(`${(SuccessEndpoints as any)[endpointName]} `)
    }
  }
}
