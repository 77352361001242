import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from './store'

export const baseQuery = fetchBaseQuery({
  baseUrl: 'https://rme.z2atravel.com/api/v1.0',
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).authReducer.accessToken
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  },
})

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: baseQuery,
  tagTypes: [
    'Agent',
    'SingleAgent',
    'ApplicationDefaults',
    'BookingTracker',
    'SupplierBookingTracker',
    'ExternalAgentBookingTracker',
    'AgentBookingTracker',
    'ClientBookingTracker',
    'BusinessTool',
    'Client',
    'ClientMembships',
    'ClientPassports',
    'ClientWallet',
    'AdjustmentHistory',
    'ExternalAgent',
    'ExternalAgentService',
    'Hotel',
    'LuxuryProgram',
    'HotelBookingMethod',
    'Supplier',
    'User',
  ],
  endpoints: () => ({}),
})
