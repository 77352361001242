import React from 'react'
import { Navigate } from 'react-router-dom'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CLoadingButton,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { userLogin } from 'redux/slices/authSlice'

const Login = () => {
  const dispatch = useAppDispatch()
  const { loading, error, username } = useAppSelector(
    (state) => state.authReducer,
  )

  function handleLogin(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const username: string = (e.target as any).username.value
    const password: string = (e.target as any).password.value

    dispatch(userLogin({ username, password }))
  }

  if (username) {
    return <Navigate to="/" />
  }
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={5}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleLogin}>
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">
                      Sign In to your account
                    </p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        name="username"
                        placeholder="Username"
                        autoComplete="username"
                        required
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        name="password"
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        required
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CLoadingButton
                          type="submit"
                          color="primary"
                          className="px-4"
                          loading={loading}
                          disabled={loading}
                        >
                          Login
                        </CLoadingButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>

        {/* <CToaster placement="top-end">
          Login Error Toast
          <CToast
            visible={error ? true : false}
            color="danger"
            className="text-white align-items-center"
          >
            <div className="d-flex">
              <CToastBody>{error}</CToastBody>
              <CToastClose className="me-2 m-auto" white />
            </div>
          </CToast>
        </CToaster> */}
      </CContainer>
    </div>
  )
}

export default Login
